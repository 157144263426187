import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import book_black from '../../../Assets/Images/book_black.png';
import white_validate from '../../../Assets/Images/white_validate.png';
import black_cross from '../../../Assets/Images/black_cross.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { AddChapterToDB, BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, RequestCancelTranslation, RequestValidateTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';
import { ITeam, TeamRequestInfo } from '../../../Api/Team';

export interface IValidation {
    team_ID: string;
    manga_ID: string;
    chapter_ID: string;
    language: string;
    translationInValidation_ID: string;
    mangaName: string;
    teamName: string;
    mangaCover: string;
    chapterNumber: number;
}

const TranslationValidateBox: React.FC<IValidation> = (d) => {
    const {user} = useContext(MainContext);
    const [open, setOpen] = useState(false);
    const [team, setTeam] = useState<ITeam | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        // if (d.team_ID)
        //     TeamRequestInfo(d.team_ID).then((t) => {
        //         setTeam(t.data);
        //     }
        // );
    }, []);

    return (
        <div className='relative w-[30%] h-[400px] bg-white border-2 border-gray-400 border-opacity-40 rounded-xl p-4 overflow-hidden'>
            {open && <div className='absolute w-full h-full left-0 top-0 bg-gray-300 bg-opacity-70 z-10 flex flex-col items-center justify-center gap-4'>
                <textarea id="refuse" className='w-[80%] h-[70%] outline-none resize-none p-2 bg-white rounded-xl shadow-lg' placeholder='Raison du refus' />
                <button onClick={() => {
                    const message = (document.getElementById('refuse') as HTMLTextAreaElement).value;
                    RequestCancelTranslation(d.translationInValidation_ID, message).then((res) => {
                        if (res.status === 200) {
                            sessionStorage.removeItem('translation_data');
                            navigate('../edit');
                        }
                    });
                }} className='relative border-2 bg-red-700 text-white Montserrat-SemiBold px-4 py-1 text-xl rounded-lg flex gap-4 items-center justify-center'>
                    Envoyer
                </button>
            </div>}
            <div className='relative w-full h-[20%] border-b-2 border-gray-400 border-opacity-40 flex justify-between items-center'>
                <div className='relative w-1/2 flex items-center overflow-hidden'>
                    <img src={logo_gatek} className='relative h-14 rounded-full border-2 border-gray-400 border-opacity-40' />
                    <h1 className='Montserrat-SemiBold ml-4 truncate'>{d.teamName}</h1>
                </div>
                <h1 className='text-sm Montserrat-SemiBold'>{d.language}</h1>
            </div>

            <div className='relative w-full h-[60%] p-2 flex gap-8'>
                <img src={backend_url+d.mangaCover} className='h-full object-contain rounded-lg' />
                <div className='relative flex flex-col gap-2'>
                    <h1 className='Montserrat-SemiBold text-xl truncate'>{d.mangaName}</h1>
                    <h1 className='text-base Montserrat-SemiBold flex gap-2 items-center'>
                        <img src={book_black} className='h-4 inline' />
                        Ch. {d.chapterNumber}
                    </h1>
                </div>
                <button className='absolute bottom-2 right-2 text-gatek_red-600 underline Montserrat-SemiBold'>lire la traduction</button>

            </div>
            <div className='relative w-full h-[20%] border-t-2 border-gray-400 border-opacity-40 flex justify-between items-center gap-8'>
                <button onClick={() => {
                    // RequestCancelTranslation(d.translationInValidation_ID, message).then((res) => {
                    //     if (res.status === 200) {
                    //         sessionStorage.removeItem('translation_data');
                    //         navigate('../edit');
                    //     }
                    // });
                }} className='relative border-2 border-gray-400 w-1/2 border-opacity-40 bg-white Montserrat-SemiBold px-4 py-1 text-xl rounded-lg flex gap-4 items-center justify-center'>
                        <img src={black_cross} className='h-6 inline' />
                    Refuser
                </button>
                <button onClick={() => {
                    RequestValidateTranslation(d.translationInValidation_ID).then((res) => {
                        if (res.status === 200)
                            navigate('../edit');
                    });
                }} className='relative border-2 bg-red-700 w-1/2  text-white Montserrat-SemiBold px-4 py-1 text-xl rounded-lg flex gap-4 items-center justify-center'>
                    <img src={white_validate} className='h-6 inline' />
                    Accepter
                </button>
            </div>
        </div>
    );
}

export default TranslationValidateBox;