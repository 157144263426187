import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import red_arrow from '../../../Assets/Images/red_arrow.png';
import recycle_bin from '../../../Assets/Images/recycle-bin.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { AddChapterToDB, BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';
import TranslationValidateBox, { IValidation } from './TranslationValidateBox';


const TranslationValidate: React.FC = () => {
    const {user} = useContext(MainContext);
    const [possibleTranslations, setPossibleTranslations] = useState<IValidation[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user)
            GetPossibleTranslation(user.id).then((res) => {
                if (res.status === 200 && res.data)
                    setPossibleTranslations(res.data);
            });
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-x-hidden bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative w-full flex flex-wrap gap-4 px-[8%] bg-gray-100 py-8'>
                    {possibleTranslations.map((translation, i) => {
                        return ( <TranslationValidateBox key={i} {...translation} /> );
                    })}
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default TranslationValidate;