import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import edit from '../../../Assets/Images/edit.png';
import eye from '../../../Assets/Images/eye.png';
import hidden from '../../../Assets/Images/hidden.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetSubscriptions, ISubscription, modifyUserInfo, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';

const Parametres: React.FC = () => {
    const {user, setuser, language, setlanguage} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [Subscriptions, setSubscriptions] = useState<ISubscription | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        GetSubscriptions(user?.id).then((res) => {
            if (res.status === 200 && res.data) {
                setSubscriptions(res.data);
            }
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full p-8 overflow-y-auto text-gatek-400 bg-white'>
                <div className='absolute w-1/2 left-[25%] flex flex-col gap-4'>
                    <div className='relative w-full flex justify-start gap-8 items-center border-b border-gatek_red-800 pb-4'>
                        <h1 className='relative Inter-Bold text-3xl text-gatek_red-800'>Mon Compte</h1>
                        <h1 className='relative Inter-Bold text-sm text-gray-900'>Avec nous depuis le 10 avril 2001</h1> {/* {user?.created_at?.toDateString()} */}
                    </div>

                    <div className='relative w-full flex justify-start gap-8 items-center border-b border-gatek_red-800 pb-8'>
                        <div className='relative w-[25%] self-start'> <h1 className='relative Inter-Regular text-xl text-gray-600'>DÉTAILS DU COMPTE</h1></div>

                        <div className='relative w-[75%] self-start flex flex-col gap-2'>
                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>{user?.email}</h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Modifier l'adresse e-mail</button>
                            </div>

                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Regular text-gatek_red-800 w-[50%] truncate'>Mot de passe: ********</h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Modifier le mot de passe</button>
                            </div>

                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Regular text-gatek_red-800 w-[50%] truncate'>Langue: Français</h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Modifier la langue</button>
                            </div>
                        </div>
                    </div>

                    <div className='relative w-full flex justify-start gap-8 items-center border-b border-gatek_red-800 pb-8'>
                        <div className='relative w-[25%] self-start flex flex-col gap-4'>
                            <h1 className='relative Inter-Regular text-xl text-gray-600'>ABONNEMENT ET FACTURATION</h1>
                            <button className='relative text-xs px-6 py-2 Inter-Regular
                            bg-gray-200 hover:bg-gray-100 text-black border-b border-gray-300 rounded duration-100'>Annuler l'abonnement</button>
                        </div>

                        <div className='relative w-[75%] self-start flex flex-col gap-2'>
                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>pi***@gmail.com</h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Gérez votre mode de paiement</button>
                            </div>

                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Regular text-gatek_red-800 w-[50%] truncate'>Prochaine date de facturation : 3 juin 2024.</h1>
                            </div>
                        </div>
                    </div>

                    <div className='relative w-full flex justify-start gap-8 items-center border-b border-gatek_red-800 pb-8'>
                        <div className='relative w-[25%] self-start flex flex-col gap-4'>
                            <h1 className='relative Inter-Regular text-xl text-gray-600'>DÉTAILS DE L'OFFRE</h1>
                        </div>

                        <div className='relative w-[75%] self-start flex flex-col gap-2'>
                            <div className='relative w-full flex justify-between'>
                                {Subscriptions?.currents && Subscriptions?.currents[0]?.abonnementType === SubscriptionType.subscription_basic && <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>Gatek [ CLASSIC ]</h1>}
                                {Subscriptions?.currents && Subscriptions?.currents[0]?.abonnementType === SubscriptionType.subscription_advanced && <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>Gatek [ PRO ]</h1>}
                                {Subscriptions?.currents && Subscriptions?.currents[0]?.abonnementType === SubscriptionType.subscription_infinite && <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>Gatek [ INFINITE ]</h1>}
                                <button onClick={() => navigate('/dashboard/subscribe')} className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Changer d'offre</button>
                            </div>
                        </div>
                    </div>

                    <div className='relative w-full flex justify-start gap-8 items-center pb-8'>
                        <div className='relative w-[25%] self-start flex flex-col gap-4'>
                            <h1 className='relative Inter-Regular text-xl text-gray-600'>PROFIL PUBLIC</h1>
                            <img className='relative rounded-md bg-gray-100 w-full h-[90%] object-contain' src={backend_url+user?.avatar} onError={handleImageError} />
                        </div>

                        <div className='relative w-[75%] self-start flex flex-col gap-2'>
                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'>{user?.username}</h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Changer de pseudo</button>
                            </div>

                            <div className='relative w-full flex justify-between'>
                                <h1 className='relative Inter-Bold text-gatek_red-800 w-[50%] truncate'></h1>
                                <button className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Changer d'avatar</button>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className='relative flex w-full h-1/2 gap-4'>
                    <div className='relative w-1/4 h-full flex flex-col'>
                        <img className='relative border border-dashed border-gatek-200 w-full h-[90%] object-contain' src={user?.avatar || logo_gatek} />
                        <input className='text-xs h-[10%]' type="file" accept="image/*" multiple onChange={(e) => {
                            if (!e.target.files || e.target.files.length === 0) return;

                            const file = e.target.files[0];
                            const reader = new FileReader();

                            reader.onloadend = () => {
                                if (user)
                                    setuser({...user, avatar: reader.result as string});
                            };

                            reader.readAsDataURL(file);
                        }} />
                    </div>
                    <img onClick={()=> {
                        setIsOpen(!isOpen);
                    }} className='relative w-5 h-5 transform transition-all hover:scale-110 cursor-pointer' src={edit}/>
                    {!isOpen ? 
                    <div className='relative w-3/4 h-full'>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Informations</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Pseudo :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.username}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Prénom :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.firstname}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Nom :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.lastname}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Email :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.email}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Date de naissance :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.birthday?.toDateString()}</p>
                        </div>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Sécurité</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Mot de passe :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>*********</p>
                        </div>
                    </div>
                    :
                    <div className='relative w-3/4 h-full'>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Informations</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Pseudo :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.username} onChange={(e) => {
                                if (user)
                                    setuser({...user, username: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Prénom :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.firstname} onChange={(e) => {
                                if (user)
                                    setuser({...user, firstname: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Nom :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.lastname} onChange={(e) => {
                                if (user)
                                    setuser({...user, lastname: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Email :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.email} onChange={(e) => {
                                if (user)
                                    setuser({...user, email: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Date de naissance :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='date' value={user?.birthday?.toDateString()} onChange={(e) => {
                                if (user)
                                    setuser({...user, birthday: new Date(e.target.value)});
                            }}/>
                        </div>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Sécurité</p>
                        <div className='flex text-xl gap-4 items-center'>
                            <p className='Poppins-SemiBold '>Password :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type={!isPasswordOpen ? 'password' : "text"} value={user?.password} onChange={(e) => {
                                if (user)
                                    setuser({...user, password: e.target.value});
                            }}/>
                            {!isPasswordOpen ? <img className='relative h-5 w-5 object-contain transform hover:scale-110 transition-all duration-150 cursor-pointer' src={eye}
                            onClick={() => {setIsPasswordOpen(!isPasswordOpen)}} /> :
                            <img className='relative h-5 w-5 object-contain transform hover:scale-110 transition-all duration-150 cursor-pointer' src={hidden} 
                            onClick={() => {setIsPasswordOpen(!isPasswordOpen)}} />}
                        </div>
                    </div>
                    }

                </div>
                <div className='relative h-1/2 w-full'>
                    <h1 className='relative w-full Poppins-SemiBold border-b border-gatek-400'>🌍 Langue :
                        <select className='Poppins-Regular text-gatek_light_red-600 outline-none' value={language} onChange={(e) => {
                            setlanguage(e.target.value);
                        }}>
                            <option value="fr">Français</option>
                            <option value="en">English</option>
                        </select>
                    </h1>
                </div> */}
            </div>
        </MainContext.Provider>
    );
}

export default Parametres;