import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import red_arrow from '../../../Assets/Images/red_arrow.png';
import recycle_bin from '../../../Assets/Images/recycle-bin.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { AddChapterToDB, BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const CreateChapter: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapter, setChapter] = useState<IDBChapter>({
        id: "",
        name: "",
        cover: undefined,
        images: [],
        chapterFree: false,
    });
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleDivClick = () => {
        fileInputRef.current?.click();
    };
    
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // Vérifier les dimensions de l'image
                // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                //     return;
                // }
    
                // Si tout est bon, procéder à la mise à jour de l'état
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Mettre à jour l'état ici
                    setChapter({...chapter, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const maxSize = maxMB * 1024 * 1024; // Taille maximale de 5MB
            const minWidth = 800; // Largeur minimale requise
            const minHeight = 600; // Hauteur minimale requise
            const filesArray: File[] = Array.from(event.target.files);
            let loadedFiles: string[] = [];
            let validImageCount = 0; // Compter le nombre d'images valides
    
            filesArray.forEach((file, index) => {
                // Vérifier la taille du fichier
                if (file.size > maxSize) {
                    console.warn("Un fichier dépasse la taille maximale autorisée et a été ignoré.");
                    return;
                }
    
                const imgURI = URL.createObjectURL(file);
                const image = new Image();
                image.onload = () => {
                    // Vérifier les dimensions de l'image
                    // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    //     return;
                    // }
    
                    // Si l'image est valide, lire le contenu du fichier
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        loadedFiles.push(reader.result as string);
    
                        // Vérifier si tous les fichiers valides sont chargés
                        if (++validImageCount === filesArray.length) {
                            // Ajouter les images valides à la suite des autres dans l'état
                            setChapter(prevState => ({
                                ...prevState,
                                images: [...(prevState.images || []), ...loadedFiles]
                            }));
                        }
                    };
                    reader.readAsDataURL(file);
                };
                image.onerror = () => {
                    console.warn("Impossible de charger une image. Elle a été ignorée.");
                };
                image.src = imgURI;
            });
        }
    };

    const CanValidate = () => {
        if (chapter.cover && chapter.name && chapter.images && chapter.images.length > 0) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (!user)
            navigate('/login');

        getMangaInfoByID(id).then((oeu) => {
            setOeuvre(oeu);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-x-hidden bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>

                <div className='relative w-full h-[10%] flex justify-center items-center p-4'>
                    <input type="text" onChange={(e) => {
                        setChapter({...chapter, name: e.target.value});
                    }} placeholder="Titre du chapitre" className='relative text-center Montserrat-Regular w-[40%] h-full p-2 m-2 bg-white border-2 border-gray-300 rounded-lg outline-none' />
                </div>

                <div className='relative w-full h-[50%] flex flex-col justify-center items-center p-4'>
                    <img className='relative border-2 border-red-700 border-dashed w-[14%] h-[90%] object-contain' src={chapter.cover || logo_gatek} />
                    <input className='text-xs w-[14%] h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                </div>

                <div className='relative w-full px-[8%] p-4 gap-4 bg-gray-100 border-b-2 border-gray-400 border-opacity-60'>
                    <h1 className='text-2xl Montserrat-SemiBold border-b-2 border-black'>{chapter.images?.length} Page(s)</h1>
                    <div className='relative w-full h-[50%] flex flex-wrap gap-4 p-4'>
                        <div onClick={handleDivClick} className='border-2 border-dashed border-red-700 w-[170px] h-[340px] flex flex-col items-center justify-center transform duration-150 hover:scale-95 cursor-pointer'>
                            <img src={redcross} className='h-[8%]' />
                            <h1 className='text-sm Montserrat-SemiBold text-red-700'>Ajouter une page</h1>
                            <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                onChange={handleFilesChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                        {chapter.images?.map((page, index) => {
                            return (
                                <div key={index} className='flex-shrink-0 flex items-center justify-center border-white relative w-[170px] h-[340px] pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la gauche
                                        let tmp = chapter.images;
                                        if (index > 0 && tmp) {
                                            [tmp[index], tmp[index-1]] = [tmp[index-1], tmp[index]];
                                            setChapter({...chapter, images: tmp});
                                        }
                                    }} className='absolute z-10 left-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        let tmp = chapter.images;
                                        tmp?.splice(index, 1);
                                        setChapter({...chapter, images: tmp});
                                    }} src={recycle_bin} className='object-contain absolute z-10 h-full w-[66%] opacity-0 hover:bg-gray-300 hover:opacity-80 rounded-full'/>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la droite
                                        let tmp = chapter.images;
                                        if (chapter.images && index < chapter.images.length-1 && tmp) {
                                            [tmp[index], tmp[index+1]] = [tmp[index+1], tmp[index]];
                                            setChapter({...chapter, images: tmp});
                                        }
                                    
                                    }} className='absolute z-10 right-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img src={page} alt={"Ajouter"} className='relative object-contain w-full h-full'/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                
                <div className='relative w-full h-[14%] flex flex-col justify-end items-end p-4'>
                    <button disabled={!CanValidate()} onClick={() => {
                        if (user && oeuvre)
                            AddChapterToDB(chapter, oeuvre).then((res) => {
                                if (res.status === 200) {
                                    navigate('../edit');
                                }
                            });
                    }} className={`relative w-[20%] h-[50%] Montserrat-SemiBold text-xl rounded-xl flex items-center justify-center gap-2 transform duration-150
                        ${CanValidate() ? 'cursor-pointer bg-gatek_red-500 text-white ' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                    `}>
                        Créer
                    </button>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default CreateChapter;