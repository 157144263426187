import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../../Context/Main';
import { FireMemberRequest, GetPostulatedMembers, ITeam, InviteMemberRequest, RecruitMemberToTeamRequest, TeamRequest } from '../../../../Api/Team';
import { handleImageError } from '../ObjectBox';
import { backend_url } from '../../../../App';

const TeamMembers: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, current_language, setcurrent_language, myteam, setmyteam} = useContext(MainContext);
    const navigate = useNavigate();
    const [InvitedUser, setInvitedUser] = useState('') 
    const [message, setMessage] = useState('') 

    // every 5 seconds, get the team postulated members
    useEffect(() => {
        const interval = setInterval(() => {
            if (myteam && myteam.team_ID)
                GetPostulatedMembers(myteam.team_ID).then((res) => {
                    if (res.status === 200) { setmyteam({...myteam, postulated: res.data}); }
                });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     if (myteam && user) {
    //         GetPostulatedMembers(myteam.team_ID).then((res) => {
    //             if (res.status === 200) { setmyteam({...myteam, postulated: res.data}); }
    //         });
    //     }
    // }, []);

    const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        // Update the state with the new value from the input field
        setInvitedUser(event.target.value);
    };

    const handleMessage = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        // Update the state with the new value from the input field
        setMessage(event.target.value);
      };

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full flex'>
                <div className='relative w-1/2 h-full flex flex-col gap-4 p-4 border-r-4 border-gatek-400'>
                    <h1 className='relative Inter-SemiBold text-3xl self-center'>Membres de l'équipe</h1>
                    {myteam && user?.id === myteam.user_leader_id && <h1 className='relative Inter-Regular w-full text-base self-center flex gap-2 p-0.5 border-b border-gatek-400'>➕Ajouter un membre :
                        <input type='text' value={InvitedUser} onChange={handleChange} placeholder="E-mail de l'utilisateur" className='relative w-1/2 outline-none italic text-gray-600' />
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            InviteMemberRequest(user.id, myteam.team_ID, InvitedUser) //# need to fix it
                        }} className='absolute right-2 bg-gatek-400 text-white w-[10%] px-2 rounded-2xl
                        transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Ajouter</button>
                    </h1>}
                    <h1 className='relative Inter-SemiBold text-2xl'>Membres actuels</h1>
                    <h1 className='relative w-full h-[5%] flex justify-between items-center gap-4 border-b border-gatek-400'>
                        <h1 className='relative Inter-SemiBold text-xl'>Avatar</h1>
                        <h1 className='relative Inter-SemiBold text-xl'>Nom</h1>
                        <h1 className='relative Inter-SemiBold text-xl'></h1>
                        {myteam && user?.id === myteam.user_leader_id && <h1 className='relative Inter-SemiBold text-xl'>Actions</h1>}
                    </h1>
                    <div className='relative w-full h-full flex flex-col overflow-y-scroll'>
                        {myteam && myteam.members && myteam.members.map((member, index) => {
                            return (
                                <div key={index} className='relative w-full h-1/6 flex justify-between items-center gap-4 border-b border-gatek-400'>
                                    <img src={backend_url+member.user_avatar} className='relative w-1/6 h-full object-contain' onError={handleImageError} />
                                    <h1 className='relative Inter-Regular text-sm'>{member.user_name}</h1>
                                    {/* <h1 className='relative Inter-Regular text-xl'>{member.user_email}</h1> */}
                                    {user?.id === myteam.user_leader_id && <button onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        FireMemberRequest(user.id, myteam.team_ID, member.user_ID).then((res) => {
                                            if (res.status === 200) {
                                                TeamRequest(user.id).then((team) => {
                                                    if (team.status === 200 && team.data.team_ID !== null) {
                                                        setmyteam(team.data);
                                                    }
                                                });
                                                GetPostulatedMembers(myteam.team_ID).then((res) => {
                                                    if (res.status === 200) { setmyteam({...myteam, postulated: res.data}); }
                                                });
                                            }
                                        });
                                    }} className='relative bg-gatek-400 text-white px-2 py-1 rounded-2xl
                                    transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Supprimer</button>}
                                    {user?.id === myteam.user_leader_id && <button onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} className='relative bg-gatek-400 text-white px-2 py-1 rounded-2xl
                                    transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Transfert lead</button>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='relative w-1/2 h-full flex flex-col gap-4 p-4'>
                    <h1 className='relative Inter-SemiBold text-3xl'>Postulants</h1>
                    <h1 className='relative w-full h-[5%] flex justify-between items-center gap-4 border-b border-gatek-400'>
                        {/* <h1 className='relative Inter-SemiBold text-xl'>User ID</h1> */}
                        <h1 className='relative Inter-SemiBold text-xl'>Message</h1>
                        {myteam && user?.id === myteam.user_leader_id && <h1 className='relative Inter-SemiBold text-xl'>Actions</h1>}
                    </h1>
                    <div className='relative w-full h-full flex flex-col overflow-y-scroll'>
                        {myteam && myteam.postulated && myteam.postulated.map((member, index) => {
                            return (
                                <div key={index} className='relative w-full p-2 flex flex-col justify-between items-center gap-4 border-b border-gatek-400'>
                                    <div className='relative w-full flex justify-between items-center gap-4'>
                                        {/* <h1 className='relative Inter-Regular text-xl'>{member.applicant_id}</h1> */}
                                        <h1 className='relative Inter-Regular text-xl'>{member.message}</h1>
                                        {user?.id === myteam.user_leader_id && <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            RecruitMemberToTeamRequest(member.applicant_id, myteam.team_ID, "", true).then((res) => {
                                                if (res.status === 200) {
                                                    TeamRequest(user.id).then((team) => {
                                                        if (team.status === 200 && team.data.team_ID !== null) {
                                                            setmyteam(team.data);
                                                        }
                                                    });
                                                    GetPostulatedMembers(myteam.team_ID).then((res) => {
                                                        if (res.status === 200) { setmyteam({...myteam, postulated: res.data}); }
                                                    });
                                                }
                                            });
                                        }} className='relative bg-gatek-400 text-white px-2 py-1 rounded-2xl
                                        transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Accepter</button>}
                                        {user?.id === myteam.user_leader_id && <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // change is_refused to true
                                            if (myteam && myteam.postulated)
                                                setmyteam({...myteam, postulated: myteam.postulated.map((postulant) => {
                                                    if (postulant.applicant_id === member.applicant_id) {
                                                        postulant.is_refused = !postulant.is_refused;
                                                    }
                                                    return postulant;
                                                })});
                                        }} className='relative bg-gatek-400 text-white px-2 py-1 rounded-2xl
                                        transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Refuser</button>}
                                    </div>
                                    {member.is_refused && <div className='relative w-full flex justify-between items-center p-2 shadow-md text-white shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                                        <textarea className='relative w-3/4 bg-transparent p-1' placeholder='Message de refus' value={message} onChange={handleMessage} />
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (user)
                                                RecruitMemberToTeamRequest(member.applicant_id, myteam.team_ID, message, false).then((res) => {
                                                    if (res.status === 200) {
                                                        TeamRequest(user.id).then((team) => {
                                                            if (team.status === 200 && team.data.team_ID !== null) {
                                                                setmyteam(team.data);
                                                            }
                                                        });
                                                        GetPostulatedMembers(myteam.team_ID).then((res) => {
                                                            if (res.status === 200) { setmyteam({...myteam, postulated: res.data}); }
                                                        });
                                                    }
                                                });
                                        }} className='relative bg-gatek-200 text-white rounded-2xl h-1/2 w-[20%]
                                        transform transition-all duration-150 hover:scale-95 hover:bg-gatek_orange-400'>Envoyer</button>
                                    </div>}
                                </div>
                            );
                        })}
                    </div>
               </div>
            </div>
        </MainContext.Provider>
    );
}

export default TeamMembers;