import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import red_group from '../../../Assets/Images/red_group.png';
import red_crown from '../../../Assets/Images/red_crown.png';
import redcross from '../../../Assets/Images/add.png';
import black_filter from '../../../Assets/Images/black_filter.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetNoTradManga } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { GetBooksClaimed, ITranslationInValidation, RequestBookClaimed, TeamRequest, TranslationInValidation } from '../../../Api/Team';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const MyTeamInterface: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [teamLeader, setTeamLeader] = useState<User | undefined>(undefined);
    const [subMenu, setSubMenu] = useState<string>('claimed');
    const [open, setOpen] = useState<boolean>(false);
    const [searchoeuvre, setsearchoeuvre] = useState<IOeuvre[]>([]);
    const [mytraduction, setmytraduction] = useState<ITranslatedBook[]>([]);
    const [TranslationInValidationData, setTranslationInValidationData] = useState<ITranslationInValidation[]>([]);
    const [members, setMembers] = useState<ITranslationInValidation[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user)
            navigate('/login');

        if (!myteam)
            navigate('/discover');

        if (myteam) {
            getUser(myteam.user_leader_id).then((u) => { setTeamLeader(u); });

            GetBooksClaimed(myteam.team_ID).then((response) => {
                if (response.status === 200)
                    setmytraduction(response.data);
            });

            TranslationInValidation(myteam.team_ID).then((res) => {
                if (res.status === 200 && res.data) {
                    setTranslationInValidationData(res.data);
                }
            });
        }


    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {open && <div onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
            }} className='absolute w-full z-10 bg-opacity-70 h-full bg-gray-200 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.stopPropagation();
                }} className='relative w-[80%] h-[80%] bg-white rounded-lg p-4'>
                    <div className='relative h-[8%] flex gap-4 items-center'>
                        <img src={black_filter} className='h-[50%] object-cover' />
                        <select id="selected_language" onChange={(e) => {
                            e.stopPropagation();
                            GetNoTradManga(e.target.value).then((res) => {
                                setsearchoeuvre(res);
                            });
                        }} className='relative p-1 w-[20%] h-[60%] outline-none text-black Poppins-SemiBold text-sm border-2 border-gray-400 border-opacity-40 rounded-xl'>
                            {AllWorldLanguages.map((langue, _) => {
                                return (
                                    <option value={langue}>{langue}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className='relative h-[90%] flex flex-wrap gap-4 items-center overflow-y-scroll overflow-x-hidden'>
                        {searchoeuvre.map((oeuvre, index) => {
                            return (
                                <div key={index} className='relative w-[240px] h-[340px]'>
                                    <img src={backend_url + oeuvre.cover} className='w-full h-[90%] object-cover' />
                                    <h1 className='absolute bottom-[10%] p-2 text-sm bg-black bg-opacity-60 w-full text-white Montserrat-SemiBold'>{oeuvre.name}</h1>
                                    <div className='relative w-full h-[10%] flex items-center justify-center'>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            const lang = (document.getElementById('selected_language') as HTMLSelectElement).value;
                                            console.log(lang, myteam);
                                            if (myteam && lang)
                                                RequestBookClaimed(oeuvre.id, myteam.team_ID, lang).then((res) => {
                                                    if (res.status === 200) {
                                                        let _d = searchoeuvre.filter((o) => o.id !== oeuvre.id);
                                                        setsearchoeuvre(_d);

                                                        GetBooksClaimed(myteam.team_ID).then((response) => {
                                                            setmytraduction(response.data);
                                                        });
                                                    }
                                                });
                                        }} className='relative w-1/2 h-[90%] rounded-lg bg-gatek_red-500 text-white Montserrat-SemiBold'>Claim</button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>}
            <div className='relative w-full h-full overflow-x-hidden overflow-y-scroll'>
                <div className='relative w-full h-[30%]'>
                <div className='absolute w-full h-full bg-black bg-opacity-80'></div>
                <img src={trad_front} className='absolute h-full object-cover -z-10' />
                    <div className='relative h-[20%]'>
                        <Navbar />
                    </div>
                </div>
                <div className='relative w-full h-[10%]'>
                    <img src={logo_gatek} className='absolute left-[8%] border-white -top-[100%] h-[200%] bg-gray-900 m-auto rounded-full border-8' />
                    <h1 className='absolute left-[18%] top-[30%] Montserrat-SemiBold transform -translate-y-1/2  text-4xl'>{myteam?.team_name}</h1>
                    <div className='absolute right-[8%] top-0'>
                        <div className='relative w-full h-1/2 flex gap-4 items-center'>
                            <img src={red_crown} className='h-full object-contain' />
                            <h1 className='text-2xl Montserrat-SemiBold'>{teamLeader?.username}</h1>
                        </div>
                        <div className='relative w-full h-1/2 flex gap-4 items-center'>
                            <img src={red_group} className='h-full object-contain' />
                            <h1 className='text-2xl Montserrat-SemiBold'>{myteam?.members?.length}</h1>
                        </div>
                    </div>
                </div>
 
                <div className='relative w-full px-[8%] mt-4'>
                    <h1 className='text-4xl Montserrat-SemiBold p-2'>A propos</h1>
                    <p className='text-sm Montserrat-Regular p-2'>{myteam?.desc}</p>
                </div>

                <div className='relative w-full px-[8%] mt-4'>
                    <div className='bg-gray-200 rounded-2xl'>
                        <div className='relative h-[10%] p-4'>
                            <button onClick={() => {
                                setSubMenu('claimed');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "claimed" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>oeuvre claimed</button>
                            <button onClick={() => {
                                setSubMenu('team');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "team" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>équipe</button>
                            <button onClick={() => {
                                setSubMenu('translation');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "translation" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>traduction en attente</button>
                        </div>

                        {subMenu === 'claimed' && <div className='relative w-full h-full p-4 flex flex-wrap gap-4'>
                            <div onClick={() => {
                                setOpen(true);
                            }} className='border-2 border-dashed border-red-700 w-[240px] h-[340px] flex flex-col items-center justify-center transform duration-150 hover:scale-95 cursor-pointer'>
                                <img src={redcross} className='h-[8%]' />
                                <h1 className='text-sm Montserrat-SemiBold text-red-700'>Claim</h1>
                            </div>
                            {mytraduction.map((trad, index) => {
                                return (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        navigate('../translate?id='+trad.manga_id+"&lang="+trad.language);
                                    }} key={index} className='relative w-[240px] h-[340px] flex flex-col gap-4 transform duration-150 hover:opacity-70 cursor-pointer'>
                                        <img src={backend_url + trad.manga_cover} className='w-full h-[90%] object-cover' />
                                        <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{trad.manga_name}</h1>
                                    </div>
                                );
                            })}
                        </div>}

                        {subMenu === 'team' && <div className='relative w-full h-full p-4 flex flex-wrap gap-4'>
                            <div className='relative w-full h-[20%] flex items-end justify-end gap-4 border-b-2 border-gray-400 border-opacity-40 p-4'>
                                <button className='relative p-2 h-[90%] rounded-lg bg-gray-300 text-gray-400 Montserrat-SemiBold'>postulant</button>
                                <button className='relative p-2 h-[90%] rounded-lg bg-red-700 text-white Montserrat-SemiBold'>inviter traducteur</button>
                            </div>
                            <div className='relative w-full h-[80%] flex flex-wrap gap-4'>
                                {myteam?.members?.map((member, index) => {
                                    return (
                                        <div key={index} className='relative w-full flex flex-col gap-4'>
                                            <img src={backend_url + member.user_avatar} className='w-full h-[90%] object-cover' />
                                            <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{member.user_name}</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default MyTeamInterface;