import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const ModifyOeuvre: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    useEffect(() => {
        if (!user)
            navigate('/login');

        if (!id) {
            navigate('/discover');
            return;
        }

        getMangaInfoByID(id).then((oeu) => {
            setOeuvre(oeu);
            if (oeu.author_ID)
                getUser(oeu.author_ID).then((u) => { setauthor(u); });
        });

        getChapter(id, "original", user?.id).then((chapter) => {
            setchapters(chapter || []);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full bg-gray-100 overflow-x-hidden'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative h-[50%] px-[8%] p-4 flex gap-4 bg-gray-100'>
                    <img src={backend_url + oeuvre?.cover} className='w-[20%] h-full object-contain' onError={handleImageError} />
                    <div className='relative border-2 border-red-700 w-[80%] rounded-xl bg-white flex flex-col p-2'>
                        <h1 className='text-2xl h-[10%] Montserrat-SemiBold border-b-2 border-gray-200'>{oeuvre?.name}</h1>
                        <textarea className='h-[80%] resize-none bg-transparent outline-none' value={oeuvre?.description} readOnly></textarea>
                        <div className='relative w-full h-[10%] flex items-end justify-end'>
                            <button className='Montserrat-SemiBold text-red-700 underline px-4'>éditer</button>
                        </div>
                    </div>
                </div>
                <div className='relative px-[8%] p-4 flex gap-4 bg-gray-100'>
                    <div className='relative flex flex-wrap gap-2 w-[70%]'>
                        {oeuvre?.tags.map((tag, index) => {
                            return (
                                <span key={index} className='relative Montserrat-SemiBold px-2 py-1 rounded-lg Montserrat-Regular bg-gatek_red-500 text-white'>{tag}</span>
                            );
                        })}
                    </div>
                    <h1 className='text-base Montserrat-SemiBold w-[30%] text-right'>{oeuvre?.originalLanguage}</h1>
                </div>

                <div className='relative w-full px-[8%] p-4 gap-4 bg-gray-100'>
                    <h1 className='text-2xl Montserrat-SemiBold border-b-2 border-black'>{chapters.length} Chapitre(s)</h1>
                    <div className='relative w-full h-[50%] flex flex-wrap gap-4 p-4'>
                        <div onClick={() => {
                            navigate('../add-chapter?id='+id);
                        }} className='border-2 border-dashed border-red-700 w-[240px] h-[340px] flex flex-col items-center justify-center transform duration-150 hover:scale-95 cursor-pointer'>
                            <img src={redcross} className='h-[8%]' />
                            <h1 className='text-sm Montserrat-SemiBold text-red-700'>Créer une chapitre</h1>
                        </div>
                        {chapters.map((chapter, index) => {
                            return (
                                <div key={index} className='relative w-[240px] h-[340px] flex flex-col gap-4'>
                                    <img src={backend_url + chapter.cover} className='w-full h-[90%] object-cover' onError={handleImageError} />
                                    <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{chapter.name}</h1>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default ModifyOeuvre;