import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up.png';
import down from '../../../Assets/Images/down.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { CreateTeamRequest, ITeam, TeamRequest } from '../../../Api/Team';
import { maxMB } from '../../Edit/AddChapter';

const CreateTeam: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [team, setTeam] = useState<ITeam>({
        team_ID: '',
        team_name: '',
        user_leader_id: '',
        cover: undefined,
        desc: ''
    });
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                //     return;
                // }
    
                const reader = new FileReader();
                reader.onloadend = () => {
                    setTeam({...team, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };


    const CanValidate = () => {
        if (team.cover && team.team_name && team.desc) {
            return true;
        }
        return false;
    };


    useEffect(() => {
        if (!user)
            navigate('/login');

    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-hidden bg-gray-100'>
                <div className='relative px-[8%] w-full h-full flex gap-4 p-4'>
                    <div className='relative w-[80%] h-full flex flex-col gap-4'>
                        <input onChange={(e) => {
                            setTeam({...team, team_name: e.target.value});
                        }} type='text' placeholder="Nom de l'équipe" className='relative w-full outline-none p-2 border-2 border-gray-400 border-opacity-40 bg-white rounded-md' />

                        <textarea onChange={(e) => {
                            setTeam({...team, desc: e.target.value});
                        }} placeholder="Description de l'équipe" className='relative w-full p-2 outline-none h-full resize-none border-2 border-gray-400 border-opacity-40 bg-white rounded-md' />
                    </div>
                    <div className='relative w-[20%] h-full flex flex-col gap-8'>
                        <div className='relative w-full h-[70%]'>
                            <img className='relative border-2 border-red-700 border-dashed w-full h-[90%] object-contain' src={team.cover || logo_gatek} />
                            <input className='text-xs w-full h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                        </div>

                        <button disabled={!CanValidate()} onClick={() => {
                            if (user && team.team_name && team.desc)
                                CreateTeamRequest(user.id, team.team_name, team.desc).then((response) => {
                                    if (response.status === 200) {
                                        navigate('../../traduction');
                                    }
                                });
                        }} className={`relative w-[full%] h-[10%] Montserrat-SemiBold text-xl rounded-xl flex items-center justify-center gap-2 transform duration-150
                            ${CanValidate() ? 'cursor-pointer bg-gatek_red-500 text-white ' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                        `}>
                            Créer
                        </button>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default CreateTeam;