import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import add_tag from '../../../Assets/Images/add_tag.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const CreateOeuvre: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre>({
        id: "",
        name: "",
        description: "",
        tags: [],
        cover: undefined,
        frontPageURL: "",
        originalLanguage : AllWorldLanguages[0],
    });
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                //     return;
                // }
    
                const reader = new FileReader();
                reader.onloadend = () => {
                    setOeuvre({...oeuvre, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    const CanValidate = () => {
        if (oeuvre.cover && oeuvre.name && oeuvre.description && oeuvre.tags.length > 0) {
            return true;
        }
        return false;
    };

    useEffect(() => {
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-hidden bg-gray-100'>
                {open && <div  onClick={(e) => {setOpen(!open)}} className='absolute z-10 w-full h-full bg-gray-400 bg-opacity-70 flex items-center justify-center'>
                    <div onClick={(e) => {e.stopPropagation()}} className='relative w-1/2 h-1/2 bg-white rounded-2xl shadow-lg gap-2 overflow-hidden overflow-y-scroll p-4'>
                        <div  className='relative flex flex-wrap gap-2 select-none'>
                            {Tags.map((tag, index) => {
                                return (
                                    <span onClick={(e) => {
                                        e.stopPropagation();

                                        if (oeuvre.tags.includes(tag)) {
                                            setOeuvre({...oeuvre, tags: oeuvre.tags.filter((t) => t !== tag)});
                                        } else {
                                            setOeuvre({...oeuvre, tags: [...oeuvre.tags, tag]});
                                        }
                                    }}
                                        key={index}
                                        className={`relative Montserrat-SemiBold px-2 py-1 rounded-lg cursor-pointer
                                            ${oeuvre.tags.includes(tag) ? 'bg-gatek_red-500 text-white' : 'bg-gray-200 text-gray-400'}    
                                        `}
                                    >
                                    {tag}
                                </span>
                                );
                            })}
                        </div>
                    </div>
                </div>}
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative h-[90%] px-[8%] py-8 flex gap-4'>
                    <div className='relative w-[20%] h-full'>
                        <div className='relative w-full h-[70%]'>
                            <img className='relative border-2 border-red-700 border-dashed w-full h-[90%] object-contain' src={oeuvre.cover || logo_gatek} />
                            <input className='text-xs w-full h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                        </div>
                        <div className='relative w-full h-[30%]'>
                            <h1 className='relative text-base Montserrat-SemiBold'>Genres de l’oeuvre :</h1>
                            <div className='relative max-h-full flex flex-wrap gap-2 items-center overflow-y-scroll'>
                                <img onClick={(e) => {
                                    setOpen(!open);
                                }} src={add_tag} className='relative w-6 h-6 cursor-pointer' />
                                {oeuvre.tags.map((tag, index) => {
                                    return (
                                        <span key={index} className='relative Montserrat-SemiBold px-2 py-1 rounded-lg Montserrat-Regular bg-gatek_red-500 text-white'>{tag}</span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='relative w-[80%] h-full flex flex-col gap-4'>
                        <div className='relative w-full h-[10%] flex gap-4 items-center'>
                            <input className='relative w-[80%] h-full p-2 border-2 border-gray-400 border-opacity-40 bg-white outline-none text-xl Montserrat-SemiBold rounded-xl' type='text' placeholder="Titre de l'oeuvre" value={oeuvre.name} onChange={(e) => {
                                setOeuvre({...oeuvre, name: e.target.value});
                            }}/>
                            <select onClick={
                                (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            } onChange={
                                (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOeuvre({...oeuvre, originalLanguage: e.target.value});
                                }
                            } className='relative p-1 w-[20%] h-[60%] outline-none text-black Poppins-SemiBold text-sm border-2 border-gray-400 border-opacity-40 rounded-xl'>
                                {AllWorldLanguages.map((langue, index) => {
                                    return (
                                        <option value={langue}>{langue}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <textarea placeholder='Description de l’oeuvre' onChange={(e) => {
                            setOeuvre({...oeuvre, description: e.target.value});
                        }} className='relative w-full h-[70%] resize-none flex Montserrat-Regular p-2 gap-4 items-center outline-none text-black Poppins-SemiBold text-sm border-2 border-gray-400 border-opacity-40 rounded-xl'>
                        </textarea>
                        <div className='relative w-full h-[20%] flex items-end justify-end'>
                            <button disabled={!CanValidate()} onClick={() => {
                                if (user)
                                    AddManga(oeuvre, user).then((res) => {
                                        if (res && res.status === 200) {
                                            navigate('../edit');
                                        } else {
                                            alert("Erreur lors de l'ajout de l'oeuvre");
                                        }
                                    });
                            }} className={`relative w-[20%] h-[50%] Montserrat-SemiBold text-xl rounded-xl flex items-center justify-center gap-2 transform duration-150
                                ${CanValidate() ? 'cursor-pointer bg-gatek_red-500 text-white ' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                            `}>
                                Créer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default CreateOeuvre;