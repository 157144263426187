import { User } from "../Interfaces/User";
import { IArticle, IChapter, IOeuvre, ITranslatedBook } from "../Interfaces/Discover";
import { backend_url } from "../App";
import { AxiosResponse, Get, Post } from "./Axios";
import { json } from "stream/consumers";

export const modifyUserInfo = async (user: User):Promise<User | undefined> => {
    const response = await Post('/credentials/modify', JSON.stringify(user));
    if (response.status === 201) {
        return response.data;
    } else {
        return undefined;
    }
}

export const getMyOeuvres = async (user: User):Promise<IOeuvre[]> => {
    console.log(user);
    if (user?.role == 'author') {
        const response = await Post('/author/info/allMangas', JSON.stringify({author_ID: user.id}));
        console.log(response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }
    return [];
}

export const getMyArticles = async (user: User):Promise<IArticle[]> => {
    console.log(user);
    if (user?.role == 'author') {
        const response = await Get('/article/fromUser', {user_ID:user.id, author_ID: user.id, nsfw: true});
        console.log("getMyArticles", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }
    return [];
}

export const getMyTranslatedOeuvres = async (user: User):Promise<ITranslatedBook[]> => {
    console.log(user);
    if (user?.role == 'translator') {
        const response = await Get('/translator/mangaTranslated', {user_ID: user.id}
        );
        console.log("getMyOeuvres", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    } else {
        return [];
    }
}

export const getUser = async (user_id: string):Promise<User | undefined> => {
    console.log(user_id);
    const response = await Post('/credentials', JSON.stringify({id: user_id}));
    console.log(response);
    if (response.status === 200) {
        response.data.id = user_id;
        return response.data;
    } else {
        return undefined;
    }
}


export const registerChapter = async (chapitre: IChapter):Promise<boolean> => {
    // const response = await axios.post(backend_url+'/register_chapter', JSON.stringify({
    //     chapitre: chapitre,
    // }), {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // });
    // if (response.status === 201) {
    //     return response.data;
    // } else {
    //     return [];
    // }
    return false
}

export enum SubscriptionType {
    subscription_basic = "subscription_basic",
    subscription_advanced = "subscription_advanced",
    subscription_infinite = "subscription_infinite",
}

export interface ISubscription {
    currents: {
        subscription_ID: string,
        abonnementType: SubscriptionType,
        abonnementExpirationDate: string,
    }[],
    previous: {
        subscription_ID: string,
        abonnementType: SubscriptionType,
        abonnementExpirationDate: string,
    }[]
}

export const GetSubscriptions = async(user_ID?: string):Promise<AxiosResponse> => {
    const response = await Get('/payment/subscriptions', {user_ID: user_ID});
    return response
}

export const StripePay = async(user_ID?: string, paymentType?: string):Promise<AxiosResponse> => {
    const response = await Post('/payment/stripe/create', JSON.stringify({
        amount: 1999,
        currency: "usd",
        userID: user_ID,
        paymentType: paymentType,
        cardInfo: {
            cardType: "visa",
            lastDigits: "4242",
            cardExpirationDate: "12/24",
            cardOwner: "John Doe"
        }
    }));
    return response
}
