import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import gray_clock from '../../../Assets/Images/gray_clock.png';
import red_clock from '../../../Assets/Images/red_clock.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';

const Edition: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [possibleTranslations, setPossibleTranslations] = useState<ITranslatedBook[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const url = new URL(window.location.href);
        if (url.pathname.endsWith("edit") || url.pathname.endsWith("edit/"))
            navigate('/edit/my-oeuvres');

        if (user)
            GetPossibleTranslation(user.id).then((res) => {
                if (res.status === 200 && res.data)
                    setPossibleTranslations(res.data);
            });
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative h-[10%] px-[8%] mb-8'>
                    <button onClick={() => {
                        navigate('./my-oeuvres');
                    }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                        ${new URL(window.location.href).pathname.endsWith("my-oeuvres") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                    `}>vos oeuvres</button>
                    <button onClick={() => {
                        navigate('./my-articles');
                    }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                        ${new URL(window.location.href).pathname.endsWith("my-articles") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                    `}>vos articles</button>

                    {possibleTranslations.length <= 0 && <div className='absolute right-[8%] Montserrat-SemiBold p-4 bg-gray-300 flex gap-4 rounded-2xl'>
                        <img src={gray_clock} className='w-6 object-contain' />
                        <h1 className='text-gray-400'>traduction en attente</h1>
                    </div>}
                    {possibleTranslations.length > 0 && <div onClick={(e) => {
                        e.stopPropagation();
                        navigate('../../translate-validation');
                    }} className='absolute duration-150 cursor-pointer select-none hover:opacity-70 right-[8%] Montserrat-SemiBold p-4 border-2 border-red-700 flex gap-4 rounded-2xl bg-white'>
                        <img src={red_clock} className='w-6 object-contain' />
                        <h1 className='text-red-700'>traduction en attente</h1>
                        <h1 className='absolute -bottom-2 -right-2 text-white bg-red-700 rounded-full px-2 '>{possibleTranslations.length}</h1>
                    </div>}
                </div>
                <div className='relative h-[80%]'>
                    <Outlet />
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Edition;