import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import logo_gatek from '../../Assets/Images/logo_gatek.png';
import arobase from '../../Assets/Images/arobase.png';
import lock from '../../Assets/Images/lock.png';
import userlogo from '../../Assets/Images/user.png';
import calendar from '../../Assets/Images/calendar.png';

import { User } from '../../Interfaces/User';
import { MainContext } from '../../Context/Main';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const {user, setuser} = useContext(MainContext);

    useEffect(() => {
        setuser({id: "",
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordConfirm: '',
            role: 'author',
            birthday: null
        })
        document.body.className = 'bg-gatek_red-500';
        return () => {
            document.body.className = '';
        };
    }, []);

    return (
        <div className='relative w-full h-full bg-gatek_red-500 flex flex-col items-center justify-center p-8 overflow-y-scroll'>
            <div className='relative h-full w-1/3'>
                <div className='relative w-full h-1/6 flex items-center justify-center cursor-pointer' onClick={() => navigate('/')}>
                    <img src={logo_gatek} />
                    <p className='PermanentMarker-Regular text-gatek_gray-100 text-6xl'>GATEK</p>
                </div>
                <div className='relative bg-gatek_gray-100 w-full rounded-xl flex flex-col px-16 py-8 overflow-hidden'>
                    <p className='relative Poppins-SemiBold text-3xl self-center'>Créez votre compte</p>

                    <p className='relative Poppins-SemiBold text-gatek_gray-700 text-sm self-center'>Missclick?
                        <button className='text-gatek_blue-500 px-1' onClick={() => {navigate("/login")}}>Connectez-vous</button>
                    </p>

                    <div className='relative w-full text-sm'>
                        <div className='relative w-full h-1/6 flex gap-4'>
                            <div>
                                <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Nom</p>
                                <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                                    <img src={userlogo} className='relative object-contain ml-4' />
                                    <input name='firstname' type='text' placeholder="Nom" value={user?.firstname} onChange={(e) => {if (user)setuser({...user, firstname: e.target.value})}}
                                    className='
                                        relative Inter-Regular outline-none text-sm w-full h-full
                                    '/>
                                </div>
                            </div>
                            <div>
                                <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Prénom</p>
                                <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                                    <img src={userlogo} className='relative object-contain ml-4' />
                                    <input name='lastname' type='text' placeholder="Prénom" value={user?.lastname} onChange={(e) => {if (user)setuser({...user, lastname: e.target.value})}}
                                    className='
                                        relative Inter-Regular outline-none text-sm w-full h-full
                                    '/>
                                </div>
                            </div>
                        </div>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Pseudo</p>
                        <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                            <img src={userlogo} className='relative object-contain ml-4' />
                            <input name='username' type='text' placeholder="Pseudo" value={user?.username} onChange={(e) => {if (user)setuser({...user, username: e.target.value})}}
                            className='
                                relative Inter-Regular outline-none text-sm w-full h-full
                            '/>
                        </div>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>E-mail</p>
                        <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                            <img src={arobase} className='relative object-contain ml-4' />
                            <input name='email' type='text' placeholder="E-mail" value={user?.email} onChange={(e) => {if (user) setuser({...user, email: e.target.value})}}
                            className='
                                relative Inter-Regular outline-none text-sm w-full h-full
                            '/>
                        </div>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Mot de passe</p>
                        <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                            <img src={lock} className='relative object-contain ml-4' />
                            <input name='password' type='password' placeholder="Mot de passe" value={user?.password} onChange={(e) => {if (user) setuser({...user, password: e.target.value})}}
                            className='
                                relative Inter-Regular outline-none text-sm w-full h-full
                            '/>
                        </div>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Confirmer mot de passe</p>
                        <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                            <img src={lock} className='relative object-contain ml-4' />
                            <input name='passwordConfirm' type='Password' placeholder="Confirmer votre mot de passe" value={user?.passwordConfirm} onChange={(e) => {if (user) setuser({...user, passwordConfirm: e.target.value})}}
                            className='
                                relative Inter-Regular outline-none text-sm w-full h-full
                            '/>
                        </div>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 mt-4'>Date de naissance</p>
                        <div className='relative w-full h-10 flex border border-gatek_gray-500 items-center justify-start gap-4 rounded-xl'>
                            <img src={calendar} className='relative object-contain ml-4' />
                            <DatePicker
                            selected={user?.birthday} 
                            onChange={(date: Date) => {if (user)setuser({...user, birthday: date})}}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Date de naissance"
                            className='relative Inter-Regular outline-none text-sm w-full h-full'
                            isClearable
                            maxDate={new Date()}
                        />
                        </div>
                    </div>
                    <p className='relative Poppins-SemiBold text-gatek_gray-700 text-sm self-center mt-4'>En m’inscrivant, j’accepte les
                        <button className='text-gatek_blue-500 px-1' onClick={() => {navigate("/conditions")}}>Conditions générales d’utilisation</button>
                        de Gatek
                    </p>
                    <button name='confirm' onClick={()=> {
                        if (user?.password !== user?.passwordConfirm)
                            return;
                        if (user?.username === '' || user?.email === '' || user?.password === '' || user?.firstname === '' || user?.lastname === '')
                            return;
                        navigate('./choice');
                    }}
                    className='
                        relative text-gatek_gray-100 Poppins-Bold self-end mt-8
                        bg-gatek_red-500 rounded-xl w-full h-16 text-xl shadow-2xl transform duration-150 overflow-hidden
                        hover:scale-105
                    '>Inscrivez-vous MAINTENANT</button>
                </div>
            </div>
        </div>
    );
}

export default Register;