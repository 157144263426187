import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../Context/Main";
import { useNavigate } from "react-router-dom";
import { GetTeamInfo, ITeam, LeaveTeam, PostulateToTeamRequest } from "../../../../Api/Team";

import logo_gatek from '../../../../Assets/Images/logo_gatek.png'
import { getUser } from "../../../../Api/User";
import { User } from "../../../../Interfaces/User";
import Loading from "../Loading/Loading";

const Team: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, current_language, setcurrent_language, setmyteam, myteam} = useContext(MainContext);
    const [teamleaderuser, setteamleaderuser] = useState<User | undefined>(undefined);
    const [subpages, setsubpages] = useState<"Postuler" | "Leave">("Postuler");
    const [message, setmessage] = useState<string>("");
    const navigate = useNavigate();


    useEffect(() => {
        if (user) {
            getUser(user.id).then((response) => {
                if (response) {
                    setteamleaderuser(response);
                }
            });
        }

        const team_id = sessionStorage.getItem('team_id');
        if (team_id !== null) {
            // Not the good route, need to be fixed

            GetTeamInfo(team_id).then((response) => {
                if (response.status === 200) {
                    console.log("GetTeamInfo ", response.data)
                    setmyteam(response.data)
                    if (response.data.user_leader_id)
                        getUser(response.data.user_leader_id).then((response) => {
                        if (response) {
                            setteamleaderuser(response);
                        }
                    });
                    // setmyteam(JSON.parse(JSON.stringify(({"team_ID":"","team_name":"","user_leader_id":"","desc":"","cover":null,"members":[],"postulated":[]}))))
                    // getUser(response.data.user_leader_id).then((response) => {
                    //     if (response) {
                    //         setteamleaderuser(response);
                    //     }
                    // });
                }
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {myteam ? <div className='relative w-full h-full p-4 overflow-y-scroll flex flex-col gap-4'>
                <div className='relative w-full h-1/2 flex p-2 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <img className='object-contain rounded-full' src={logo_gatek} />
                    <div className='relative h-full w-full flex flex-col gap-2'>
                        <p className='Poppins-SemiBold text-3xl border-b border-gatek-200 text-white'>{myteam.team_name}</p>
                        {teamleaderuser && <p className='Inter-SemiBold text-white text-xl'>👑{teamleaderuser.username}</p>}
                        <p className='Inter-SemiBold text-white text-xl'>👤{myteam.members?.length} membre(s)</p>
                        <h1 className='Inter-SemiBold text-white text-xl'>📝 Description:</h1>
                        <p className='Inter-SemiBold text-white text-xl h-1/2 w-full overflow-y-scroll'>{myteam.desc}</p>
                    </div>
                </div>
                    <div className='relative w-full h-1/2 flex flex-col gap-4 p-2'>
                        <div className="relative w-full h-[5%] grid grid-cols-2">
                            <button onClick={() => setsubpages("Postuler")} className={`Poppins-SemiBold text-2xl  rounded-md
                                ${subpages === "Postuler" ? 'border-2 border-gatek-400' : 'border-b-2 bg-gatek-400 text-white'}
                            `}>Postuler</button>
                            <button onClick={() => setsubpages("Leave")} className={`Poppins-SemiBold text-2xl  rounded-md
                                ${subpages === "Leave" ? 'border-2 border-gatek-400' : 'border-b-2 bg-gatek-400 text-white'}
                            `}>Quitter</button>
                        </div>
                        {subpages === "Postuler" &&
                            <div className='relative w-full h-[95%] flex flex-col p-2 gap-2 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 text-white'>
                                <h1 className='Poppins-SemiBold text-2xl text-center border-b-2'>Postuler pour rejoindre l'équipe</h1>
                                <h1 className='Poppins-SemiBold text-xl'>📝 Message:</h1>
                                <textarea className='w-full h-1/2 p-2 outline-none bg-white rounded-md text-black' value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                                <button className='Poppins-SemiBold w-1/2 self-center text-xl bg-gatek-200 text-white rounded-md p-2 transform hover:scale-95 duration-150' onClick={() => {
                                    if (user)
                                        PostulateToTeamRequest(user.id, myteam.team_ID, message);   
                                }}>Envoyer</button>
                            </div>
                        }
                        {subpages === "Leave" &&
                            <div className='relative w-full h-[95%] flex flex-col p-2 gap-2 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 text-white'>
                                <h1 className='Poppins-SemiBold text-2xl text-center border-b-2'>Quitter l'équipe</h1>
                                <button className='Poppins-SemiBold w-1/2 self-center text-xl bg-gatek-200 text-white rounded-md p-2 transform hover:scale-95 duration-150' onClick={() => {
                                    LeaveTeam(myteam.team_ID).then((response) => {
                                        if (response.status === 200) {
                                            setmyteam(undefined);
                                        }
                                    });
                                }}>Quitter</button>
                            </div>
                        }
                    </div>
            </div> : <div className='relative w-full h-full flex flex-col gap-2'>
                <div className='relative w-full h-1/4 flex flex-col gap-4 items-center justify-center border-b border-gatek-400'>
                    <h1 className='relative Inter-SemiBold italic text-3xl'>Vous n'avez pas encore d'équipe de traduction. C'est le moment de se lancer !</h1>
                    <button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate('/dashboard/teamcreate');
                    }} className='relative Inter-Bold text-3xl bg-gatek-400 py-2 px-8 rounded-2xl text-white shadow-md shadow-black transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Je me lance !</button>
                </div>
                <div className='relative w-full h-full flex flex-col'>
                    <h1 className='relative Inter-SemiBold text-3xl'>Teams qui recrutent</h1>
                </div>
            </div> }
        </MainContext.Provider>
    );
}

export default Team;