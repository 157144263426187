import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import logo_gatek from '../../Assets/Images/logo_gatek.png';
import arobase from '../../Assets/Images/arobase.png';
import lock from '../../Assets/Images/lock.png';
import { loginRequest } from '../../Api/login';
import { MainContext } from '../../Context/Main';
import WatchDog from '../WatchDog/WatchDog';
import { GetStorage, SetStorage } from '../../Api/Storage';
import { Get } from '../../Api/Axios';

const Login: React.FC = () => {
    const {user, setuser, setToken} = useContext(MainContext);

    const [email, setemail] = useState<string>('');
    const [password, setpassword] = useState<string>('');
    const [error, seterror] = useState<string>('');

    const navigate = useNavigate();
    return (
        <div className='relative w-full h-full bg-gatek_red-500 flex flex-col items-center justify-center p-8'>
            <div className='absolute right-2 top-2'>
                <WatchDog />
            </div>
            <div className='relative h-full w-1/3 flex flex-col'>
                <div className='relative w-full h-1/6 flex items-center justify-center cursor-pointer' onClick={() => navigate('/')}>
                    <img src={logo_gatek} />
                    <p className='PermanentMarker-Regular text-gatek_gray-100 text-6xl'>GATEK</p>
                </div>
                <div className='relative bg-gatek_gray-100 w-full h-5/6 rounded-xl flex flex-col px-16 py-8 overflow-hidden'>
                    <p className='relative Poppins-SemiBold text-3xl self-center'>Connectez-vous !</p>
                    <div className='relative w-full h-1/2 grid grid-cols-1'>
                        <div className='relative w-full h-1/2 flex flex-col'>
                            <p className='relative Poppins-SemiBold text-gatek_gray-700'>E-mail</p>
                            <div className='relative w-full h-full flex border p-2 border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                                <img src={arobase} className='relative object-contain' />
                                <input type='email' name='email' placeholder="Entrez votre e-mail" value={email} onChange={(e) => {setemail(e.target.value)}}
                                className='
                                    relative Inter-SemiBold outline-none text-sm w-full h-full
                                '/>
                            </div>
                        </div>
                        <div className='relative w-full h-1/2 flex flex-col'>
                            <p className='relative Poppins-SemiBold text-gatek_gray-700'>Mot de passe</p>
                            <div className='relative w-full h-full flex border border-gatek_gray-500 items-center justify-start gap-4 overflow-hidden rounded-xl'>
                                <img src={lock} className='relative object-contain p-2' />
                                <input type='password' name='password' placeholder="Entrez votre mot de passe" value={password} onChange={(e) => {setpassword(e.target.value)}}
                                className='
                                    relative Inter-SemiBold outline-none text-sm w-full h-full
                                '/>
                            </div>
                        </div>
                    </div>
                    <div className='relative w-full h-1/3 flex flex-col gap-4'>
                        <div className='relative w-full h-1/3 flex flex-row justify-between items-center text-sm'>
                            <p className='relative items-center flex gap-1 Inter-Regular '>
                                <input type='checkbox' className='relative w-4 h-4' />Se souvenir de moi
                            </p>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                navigate('../forgotpassword');
                            }} className='relative text-gatek_blue-500 Inter-Bold'>Mot de passe oublié ?</button>
                        </div>
                        {error !== '' && <p className='relative Poppins-SemiBold text-gatek_red-500 text-sm self-center animate-pulse'>⚠️{error}</p>}
                        <button name='login' onClick={() => {
                            if (email === '' && password === '')
                                return;
                            loginRequest(email, password).then((res) => {
                                console.log("login", res);
                                if (res.status === 200) {
                                    setToken(res.data.token);
                                    sessionStorage.setItem('token', res.data.token);
                                    SetStorage(res.data.token, 'user_id', res.data.id);
                                    // sessionStorage.setItem('user_id', res.data.id);
                                    navigate('/discover');
                                } else {
                                    if (res.data)
                                        seterror(res.data.error);
                                    else
                                        seterror('Une erreur est survenue');
                                }
                            })
                        }}
                        className='
                            relative text-gatek_gray-100 Poppins-Bold self-end
                            bg-gatek_red-500 rounded-xl w-full h-1/3 text-xl shadow-2xl transform duration-150 overflow-hidden
                            hover:scale-105
                        '>Se connecter</button>
                        <p className='relative Poppins-SemiBold text-gatek_gray-700 text-sm self-center'>Pas de compte?
                            <button name='register' className='text-gatek_blue-500 px-1' onClick={() => {navigate("/register")}}>Inscrivez-vous</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;