import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import ico_trad_white from '../../../Assets/Images/ico_trad_white.png';
import ico_trad_black from '../../../Assets/Images/ico_trad_black.png';

import ico_lib_white from '../../../Assets/Images/ico_lib_white.png';
import ico_lib_black from '../../../Assets/Images/ico_lib_black.png';

import ico_search_white from '../../../Assets/Images/ico_search_white.png';
import ico_search_black from '../../../Assets/Images/ico_search_black.png';

import ico_notif_white from '../../../Assets/Images/ico_notif_white.png';
import ico_notif_black from '../../../Assets/Images/ico_notif_black.png';

import edition_white from '../../../Assets/Images/edition_white.png';
import edition_black from '../../../Assets/Images/edition_black.png';

import disconnect_white from '../../../Assets/Images/disconnect_white.png';
import disconnect_black from '../../../Assets/Images/disconnect_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // URL de votre image de remplacement
    e.currentTarget.src = logo_gatek;
};

const Navbar: React.FC<{is_black?: boolean}> = (d) => {
    const {user, setToken, setuser} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const color = d.is_black ? 'black' : 'white';

    useEffect(() => {
    }, []);

    return (
        <div className={`
            relative h-full w-full px-[8%] flex items-center justify-between gap-[3%]
            ${d.is_black && 'border-b-2 border-gray-400 border-opacity-50'}
        `}>
            <div className='relative h-full w-1/2 flex gap-[8%] items-center p-1'>
                <h1 onClick={(e) => {
                    e.stopPropagation();
                    navigate('/discover');
                }} className={'text-'+color+' PermanentMarker-Regular text-3xl'}>GATEK</h1>
                {user?.role !== "author" ? <button onClick={() => {
                    navigate('/traduction');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? ico_trad_black : ico_trad_white} className='h-5 object-contain' />
                    Traduction
                </button> :<button onClick={() => {
                    navigate('/edit');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? edition_black : edition_white} className='h-5 object-contain' />
                    Edition
                </button>}
                <button onClick={() => {
                    navigate('/library');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? ico_lib_black : ico_lib_white} className='h-5 object-contain' />
                    Bibliothèque
                </button>
            </div>
            <div className='relative h-full w-1/4 flex justify-end gap-[5%] items-center p-1'>
                <img src={d.is_black ? ico_search_black : ico_search_white} className='h-6 object-contain' />
                <img src={d.is_black ? ico_notif_black : ico_notif_white} className='h-6 object-contain' />
                <div onClick={(e) => {
                    e.stopPropagation();
                    navigate('/parameters');
                    // setIsOpen(!isOpen);
                }}
                    onMouseEnter={()=>setIsOpen(true)}
                className='relative h-full flex items-center truncate'>
                    <img className='relative justify-end bg-gatek_gray-100 rounded-full h-8 w-8 p-1 cursor-pointer' src={backend_url+user?.avatar} alt='' onError={handleImageError} />
                </div>
                <img onClick={(e) => {
                    e.stopPropagation();
                    setToken(undefined);
                    sessionStorage.removeItem('token');
                    setuser(undefined);
                    navigate("/");
                }} src={d.is_black ? disconnect_black : disconnect_white} className='h-6 object-contain cursor-pointer' />
            </div>
            {/* {isOpen && (
                <div onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen);}} onMouseLeave={()=>setIsOpen(false)} className='absolute w-[10%] h-[100%] z-40 right-[9%] transform duration-200 fade-in-complete'>
                    <div className='relative w-full h-[28%] bg-transparent'></div>
                    <div className='w-full max-h-[55%] bg-gatek-400 flex flex-col text-sm Inter-Regular text-white truncate p-2'>
                        <button onClick={() => {
                            navigate("./parametres");
                        }} className='hover:underline text-left'>Mon compte</button>
                    </div>
                    <div className='relative h-[17%] w-full border-t bg-gatek-400 text-sm Inter-Regular text-white truncate flex items-center justify-center'>
                        <button onClick={() => {
                            setToken(undefined);
                            sessionStorage.removeItem('token');
                            setuser(undefined);
                            navigate("/");
                        }} className='hover:underline'>Se déconnecter</button>
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default Navbar;