import axios from 'axios';
import { Post, Get, AxiosResponse } from './Axios';
import { IBoxe } from '../Components/Dashboard/Subcomponent/OeuvreTranslationInterface';

export interface IMember {
    user_ID: string,
    user_name: string,
    user_avatar: any,
    user_email: string,
    user_is_leader: boolean,
}

export interface ITeam {
    team_ID: string,
    team_name: string,
    user_leader_id: string,
    desc?: string,
    cover?: any,
    members?: IMember[],
    postulated?: IPostulate[],
}

export interface IPostulate {
    applicant_id: string,
    message: string,
    is_refused?: boolean,
}

// Route existante mais pas sur dev pour le moment
export const CreateTeamRequest = async (userID: string, team_name:string, desc: string):Promise<AxiosResponse> => {    
    return await Post('/translator/team/create', JSON.stringify({ user_ID: userID, team_name: team_name, desc: desc}));
    return {status: 200, statusText: "No Team", data: {}}
}

export const TeamRequest = async (userID: string): Promise<AxiosResponse> => {
    console.log("TeamRequest");

    const response = await Get('/translator/team',  {"user_ID": userID})
    console.log("res", response)
    // return {status: 300, statusText: "No Team", data: {}}
    return response
};

export const TeamRequestInfo = async (TeamID: string): Promise<AxiosResponse> => {
    console.log("TeamRequest");

    const response = await Get('/translator/team/info',  {"team_ID": TeamID})
    console.log("res", response)
    // return {status: 300, statusText: "No Team", data: {}}
    return response
};

export const PostulateForTeamRequest = async (userID: string, teamID:string, message: string):Promise<AxiosResponse> => {    
    const response = await Post('/translator/team/postulate', JSON.stringify({ user_ID: userID, team_ID: teamID, message: message}));
    console.log(response);
    return response;
}

export const RecruitMemberToTeamRequest = async (userID: string, teamID:string, message: string, authorize: boolean):Promise<AxiosResponse> => {
    console.log('aaaaaaaaaa', userID, teamID, message, authorize)    
    const response = await Post('/translator/team/postulated/authorize', JSON.stringify({ user_ID: userID, team_ID: teamID, message: message, authorize: authorize}));
    console.log(response);
    return response;
}

export const GetPostulatedMembers = async (team_ID: string):Promise<AxiosResponse> => {
    const response = await Get('/translator/team/postulated',  {"team_ID": team_ID})
    console.log("res", response)
    return response;
}

/* ----------------------- */

export const GetBooksClaimed = async (team_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/claimed', JSON.stringify({team_ID: team_ID}));
    console.log(response);
    return response;
    // return {status: 200, statusText: "Success", data: JSON.parse(JSON.stringify({"manga_claimed": [{"manga_id": "66044bec7fe92747393d208a", "manga_name": "name1", "manga_cover": "url1", "language": "fr"}, {"manga_id": "234", "manga_name": "name2", "manga_cover": "url2", "language": "fr"}]}))}
}

export const ClaimBooksRequest = async (book_ID: string, team_ID: string, language: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/claim', JSON.stringify({book_ID, team_ID, language}));
    console.log(response);
    return response;
    // return {status: 200, statusText: "Success", data: JSON.stringify({"manga_claimed": [{"manga_id": "123", "manga_name": "name1", "manga_cover": "url1", "language": "fr"}, {"manga_id": "234", "manga_name": "name2", "manga_cover": "url2", "language": "fr"}]}])}
}


export const GetChapterToTranslate = async (book_ID: string, team_ID:string, chapter_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/manga/chapter/process/translated', JSON.stringify({book_ID, team_ID, chapter_ID}));
    return {status: 200, statusText: "Success", data: JSON.stringify([{"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}, {"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}])}
    return response
}

//{ "book_ID": required, “team_ID”:required, “chapter_ID”: required, images: { “1”: “texte”, . . .  }}

export const GetPreviewPageTranslated = async (book_ID: string, team_ID:string, chapter_ID: string, zones: any):Promise<AxiosResponse> => {
    const response = await Post('/manga/chapter/process/preview', JSON.stringify({book_ID, team_ID, chapter_ID, zones}));
    return {status: 200, statusText: "Success", data: JSON.stringify({"page": "cover"})}
    return response
}

/* ----------------------- */

export const FireMemberRequest = async (user_ID: string, team_ID:string, memberFired_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/fire', JSON.stringify({user_ID, team_ID, memberFired_ID}));
    // return {status: 200, statusText: "Success", data: JSON.stringify([{"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}, {"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}])}
    return response
}

export const InviteMemberRequest = async (user_ID: string, team_ID:string, userName: string):Promise<AxiosResponse> => {
    const responsePublicID = await Get('/credentials/publicID', {name: userName});
    const response = await Post('/translator/team/invite', JSON.stringify({user_ID, team_ID, userInvited_ID: responsePublicID.data}));
    // return {status: 200, statusText: "Success", data: JSON.stringify([{"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}, {"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}])}
    return response
}

export const PostulateToTeamRequest = async (user_ID: string, team_ID:string, message: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/postulate', JSON.stringify({user_ID, team_ID, message}));
    // return {status: 200, statusText: "Success", data: JSON.stringify([{"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}, {"page_cover": "cover", "zones": [{"idx": "1", "text": "qtrzerze"}, {"idx": "2", "text": "qtrzerze"}, {"idx": "3", "text": "qtrzerze"}, {"idx": "4", "text": "qtrzerze"}]}])}
    return response
}

export const getProcessImages = async(book_ID: string, chapter_ID: string):Promise<AxiosResponse> => {
    const response = await Get('/manga/chapter/process/withoutText', {manga_ID: book_ID, "chapter_ID": chapter_ID});
    // return {status: 200, statusText: "Success", data: JSON.stringify([{"likes": 21, "dislikes": 42, "comment": "niceuh", "response": ["65853213a8877b2216925b4f", "65853213a8877b2216925b4f"], "creationDate": 1704509462, "author_ID": "65adfd1767b7a99733388f1e", "id": "658532f391c61ca9fbf82ffd", "likedByUser": false}])}
    return response
}

export interface ITranslationInValidation {
    author_ID: string,
    manga_ID: string,
    chapter_ID: string,
    language: string,
    translationInValidation_ID: string,
    chapterName: string,
    mangaName: string,
    mangaCover: string,
    message: string,
    refusedTime: string,
}

export const TranslationInValidation = async(team_ID?: string):Promise<AxiosResponse> => {
    const response = await Get('/translator/team/translationInValidation', {team_ID: team_ID});
    return response
}

export const CancelTranslation = async(team_ID?: string, manga_ID?: string, chapter_ID?: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/cancelTranslation', {team_ID: team_ID, manga_ID: manga_ID, chapter_ID: chapter_ID});
    return response
}

/* ----------------------- */

export const GetTeamInfo = async (team_id: string):Promise<AxiosResponse> => {

    const response = await Get('/translator/team/public', {team_ID: team_id});
    return response
}

export const GetPreviewImage = async(book_ID: string, chapter_ID: string, team_ID: string, imageWithOutTextUrl: string, boxes: IBoxe[]):Promise<AxiosResponse> => {
    const response = await Post('/manga/chapter/process/preview', {manga_ID: book_ID, "chapter_ID": chapter_ID, team_ID: team_ID, imageWithoutText: imageWithOutTextUrl, boxes: boxes});
    return response
}

export const RequestChapterValidation = async(book_ID: string, chapter_ID: string, team_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/manga/chapter/process/validateTranslation', {manga_ID: book_ID, "chapter_ID": chapter_ID, team_ID: team_ID});
    return response
}

export const RequestBookClaimed = async(book_ID: string, team_ID: string, language: string):Promise<AxiosResponse> => {
    console.log("RequestBookClaimed", book_ID, team_ID, language);
    const response = await Post('/translator/team/claim', {manga_ID: book_ID, team_ID: team_ID, language: language});
    return response
}

export const LeaveTeam = async (team_id: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/leave', JSON.stringify({team_ID: team_id}));
    return response
}

export const Unclaim = async(book_ID: string, team_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/unclaim', { manga_ID: book_ID, team_ID: team_ID });
    return response
}
