import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import React, {useContext, useEffect, useState} from 'react';
import { SearchResponseType } from '../../../Api/Search';
import { backend_url } from '../../../App';
import ObjectBox, { handleImageError } from './ObjectBox';
import { MainContext } from '../../../Context/Main';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import OeuvreModal from './OeuvreModal';
import ArticleModal from './ArticleModal';
import { getMangaInfo } from '../../../Api/Oeuvres';
import manga_ico from '../../../Assets/Images/comic.png';
import author_ico from '../../../Assets/Images/writer.png';
import team_ico from '../../../Assets/Images/partners.png';

interface Props {
    searchTab: SearchResponseType | undefined;
    setOpenSearch: any;
}

const SearchSuggestions: React.FC<Props> = ({ searchTab, setOpenSearch }) => {
    const {user, setuser, setseedetails, setseearticle} = useContext(MainContext);
    const hasSuggestions = !!searchTab && (searchTab.books.length > 0 || searchTab.authors.length > 0 || searchTab.teams.length > 0);

    return (
        <div className='relative w-full h-full p-8'>
            {searchTab?.books && searchTab?.books.length > 0 && <div className='relative w-full h-[50%]'><ObjectBox setOpenSearch={setOpenSearch} label="Oeuvre(s)" grid={10} oeuvres={searchTab.books} white={true}/></div>}
            {searchTab?.authors && searchTab?.authors.length > 0 && <div className='relative w-full h-[40%]'><ObjectBox setOpenSearch={setOpenSearch} label="Membre(s)" grid={8} authors={searchTab.authors} white={true}/></div>}
            {searchTab?.teams && searchTab?.teams.length > 0 && <div className='relative w-full h-[40%]'><ObjectBox setOpenSearch={setOpenSearch} label="Équipe(s) de traduction" grid={8} teams={searchTab.teams} white={true}/></div>}

            {!hasSuggestions && <h1 className='absolute Inter-Regular italic top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl'>Aucun résultat</h1>}
        </div>
    );
}

export default SearchSuggestions;
